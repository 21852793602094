import React from 'react';
import './App.scss';

import Main from "./modules/Main";

class App extends React.Component {
  render() {
    return (
      <Main />
    );
  }
}


export default App;
